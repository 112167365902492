//* General

@font-face {
  font-family: 'AritmaUten';
  src: url('../../assets/fonts/AritmaUten1.0-Regular.ttf') format('truetype');
}

body {
  font-family: 'AritmaUten', 'Source Sans Pro', sans-serif;
}

//* ID Sections

#logo-header {
  width: 9.5rem;
  min-width: 9.5rem;
}

//* Forms / React Select

.react-select__control,
.form-control {
  &:hover {
    border-color: $secondary !important;
    box-shadow: none !important;
  }
}

//* Colors

.text-info-darker {
  color: #00667a;
}

//* Buttons

.btn-primary {
  color: $secondary;
}

//* Utilities

.h-80 {
  height: 80%;
}

.w-80 {
  width: 80%;
}

.bottom-10 {
  bottom: 10em;
}

.pe-cursor {
  cursor: pointer;
}

//* Loading Indicator

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: $secondary;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

//* Media Queries

@include media-breakpoint-up(sm) {
  .w-md-50 {
    width: 50% !important;
  }

  .vh-md-100 {
    height: 100vh;
  }
}

@include media-breakpoint-down(sm) {
  .breadcrumb {
    font-size: 0.85rem !important;
  }
}

@include media-breakpoint-down(md) {
  .justify-content-center-md {
    justify-content: center !important;
  }

  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.3rem;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    padding-right: 0.3rem;
  }

  .fs-1 {
    font-size: calc(1.325rem + 0.9vw) !important;
  }

  .fs-5 {
    font-size: 1.1rem !important;
  }
}
