//* Colors

$primary: #fb8caf;
$secondary: #45095a;
$body-bg: #f9f0ee;

//* General

$breadcrumb-divider: quote('>');
$border-radius: 0;

//* Forms

$input-padding-y: 10px;
$form-label-color: $secondary;
$input-color: $secondary;
$input-focus-border-color: $secondary;
$input-focus-box-shadow: none;
